<template>
  <!-- 使用弹窗父子页面切换容器组件 -->
  <div class="view-task">
    <Nav
      :moduleTreeObj="moduleTreeObj"
      :initParams="initParams"
      activeModuleKey="viewTask"
      @closeModal="handleClose"
      @viewRoute="handleViewRoute"
      @handleToTrackVideo="handleToTrackVideo"
    />
  </div>
</template>

<script>
import Nav from '@/views/container/nav'

export default {
  name: 'ViewTaskModal',
  components: { Nav },
  props: {
    info: {
      type: Object,
      default: () => { }
    },
    position: {
      type: Object,
      default: () => { }
    },
    isShowModal: {
      type: Boolean,
      default: () => { }
    },
    vehicleId: {
      type: String,
      default: () => { }
    }
  },
  watch: {
    'info.vehicleId'(newVal, oldVal) {
      this.$emit('closeViewTaskModal')
    }
  },
  data() {
    // 节流控制
    return {
      // 配置在弹窗容器组件中所使用的父子组件，并建立组件的父子关系
      moduleTreeObj: {
        key: 'viewTask',
        title: this.vehicleId + '-任务查看',
        component: () => import('./ViewTaskModal'),
        children: [
          {
            key: 'taskRecord',
            title: '任务记录',
            component: () => import('@/views/iot/vehicleTaskRecord'),
            children: [
              {
                key: 'taskRecordDetail',
                title: '任务报告',
                component: () => import('@/views/iot/vehicleTaskRecord/modules/CreateForm'),
                children: [
                  {
                    key: 'trackDetail',
                    title: '轨迹详情',
                    component: () => import('@/views/monitor/monitorComponents/trackpage'),
                    children: [
                      {
                        key: 'vehicleTaskArea',
                        title: '围栏',
                        backTop: true,
                        component: () => import('@/views/iot/vehicleTaskArea'),
                        children: []
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      initParams: {
        vehicleId: this.vehicleId,
        position: this.position,
        info: this.info,
        isShowModal: this.isShowModal,
        width: document.body.clientWidth - this.position.left - 10,
        height: '100%'
      }
    }
  },
  computed: {},
  created() { },
  methods: {
    handleClose() {
      console.log('ViewTaskContainer Close')
      this.$emit('closeViewTaskModal')
    },
    handleViewRoute(routeId) {
      console.log('handleViewRoute')
      // 由Nav组件传递过来的事件
      this.$emit('viewRoute', routeId)
    },
    handleToTrackVideo(e) {
      this.$emit('handleToTrackVideo', e)
    }
  },
  beforeDestroy() { }
}
</script>
